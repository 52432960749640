import {
  WS_CONNECTED,
  WS_DISCONNECTED,
  WS_TASK_STARTED,
  WS_UPDATE_PROGRESS,
  WS_UPDATE_RESULT
} from "../types";


export const initialState = {
  running_tasks: []
};

const tasksReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case WS_TASK_STARTED:
      return {
        ...state,
        running_tasks: [
          ...state.running_tasks,
          {
            id: payload.id,
            progress: null,
            result: []
          }
        ]
      };
    case WS_UPDATE_PROGRESS:
      return {
        ...state,
        running_tasks: state.running_tasks.map(task => {
          if (task.id !== payload.id) {
            return task;
          }

          return {
            ...task,
            progress: payload.progress
          };
        })
      };
    case WS_UPDATE_RESULT:
      return {
        ...state,
        running_tasks: state.running_tasks.map(task => {
          if (task.id !== payload.id) {
            return task;
          }

          return {
            ...task,
            result: payload.result
          };
        })
      };
    case WS_CONNECTED:
      return {
        ...state
      };
    case WS_DISCONNECTED:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default tasksReducer;
