import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faClipboard } from "@fortawesome/free-solid-svg-icons";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Container,
  Button,
} from "react-bootstrap";
import { API_URL } from "../config";
import Image from "next/image";
import { useState } from "react";
import { logout } from "../api/auth";
import { LOGOUT_SUCCESS } from "../redux/types";

export default function NavigationBar() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState("");

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const logoutHandler = () => {
    const res = logout();
    if (res === 200) {
      dispatch({ type: LOGOUT_SUCCESS });
    } else {
      dispatch({ type: LOGOUT_FAIL });
    }
  };
  const authLinks = (
    <>
      <NavDropdown
        title={<FontAwesomeIcon icon={faUser} />}
        id="navbarScrollingDropdownUser"
        align="end"
      >
        <Link href={`${API_URL}/admin/`} target="_blank" passHref>
          <NavDropdown.Item>Admin</NavDropdown.Item>
        </Link>
        <Link href="/spectra/exports" passHref>
          <NavDropdown.Item>Exports</NavDropdown.Item>
        </Link>
        <NavDropdown.Divider />
        <Link href="/profile" passHref>
          <NavDropdown.Item disabled>Profile</NavDropdown.Item>
        </Link>
        <Link href="/my-spectra" passHref>
          <NavDropdown.Item>My spectra</NavDropdown.Item>
        </Link>
        {/*<Link href="/change-password" passHref>*/}
        {/*  <NavDropdown.Item>Password change</NavDropdown.Item>*/}
        {/*</Link>*/}
        <NavDropdown.Item>
          <a className="text-danger" onClick={logoutHandler}>
            Logout
          </a>
        </NavDropdown.Item>
      </NavDropdown>
      <Nav.Item>
        <Link href="/clipboard" passHref>
          <Nav.Link>
            <FontAwesomeIcon icon={faClipboard} />
          </Nav.Link>
        </Link>
      </Nav.Item>
    </>
  );

  const guestLinks = (
    <>
      <Nav.Item>
        <Link href="/login" passHref>
          <Nav.Link active={router.pathname === "/login"}>Login</Nav.Link>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link href="/register" passHref>
          <Nav.Link active={router.pathname === "/register"}>
            Registration
          </Nav.Link>
        </Link>
      </Nav.Item>
    </>
  );

  return (
    <Navbar
      variant="light"
      bg="light"
      expand="lg"
      sticky="top"
      className={"py-1 mb-3"}
      collapseOnSelect={true}
    >
      <Container>
        <Link href="/" passHref>
          <a>
            <Image
              src="/logo_final_Color_gradient.svg"
              alt="Ramanbase logo"
              width={70 * 2.35714}
              height={70}
            />
          </a>
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0">
            <Nav.Item>
              <Link href="/spectra" passHref>
                <Nav.Link active={router.pathname === "/spectra"}>
                  Spectra
                </Nav.Link>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link href="/spectra/upload" passHref>
                <Nav.Link active={router.pathname === "/spectra/upload"}>
                  Upload
                </Nav.Link>
              </Link>
            </Nav.Item>
            {isAuthenticated ? (
              <>
                <NavDropdown
                  title="Tools"
                  id="navbarScrollingDropdownTools"
                >
                  <Link href="/tools/compare" passHref>
                    <NavDropdown.Item>Compare spectra</NavDropdown.Item>
                  </Link>
                  <Link href="/tools/predict-keywords" passHref>
                    <NavDropdown.Item>Keyword prediction</NavDropdown.Item>
                  </Link>
                </NavDropdown>
                <Nav.Item>
                  <Link href="/stats" passHref>
                    <Nav.Link active={router.pathname === "/stats"}>
                      Stats
                    </Nav.Link>
                  </Link>
                </Nav.Item>
              </>
            ) : null}
            <NavDropdown
              title="Tutorials"
              id="navbarScrollingDropdownTutorials"
            >
              <Link href="/tutorials/download" passHref>
                <NavDropdown.Item disabled>Download</NavDropdown.Item>
              </Link>
              <Link href="/tutorials/usage" passHref>
                <NavDropdown.Item disabled>Usage policies</NavDropdown.Item>
              </Link>
            </NavDropdown>
            <NavDropdown title="About" id="navbarScrollingDropdownAbout">
              <Link href="/about" passHref>
                <NavDropdown.Item>About</NavDropdown.Item>
              </Link>
              <Link href="/about/mission" passHref>
                <NavDropdown.Item>Mission</NavDropdown.Item>
              </Link>
              <Link href="/about/cite-us" passHref>
                <NavDropdown.Item disabled>Cite us</NavDropdown.Item>
              </Link>
              <Link href="/about/applications" passHref>
                <NavDropdown.Item disabled>Applications</NavDropdown.Item>
              </Link>
              <Link href="/about/history" passHref>
                <NavDropdown.Item>Historical overview</NavDropdown.Item>
              </Link>
              <Link href="/about/glossary" passHref>
                <NavDropdown.Item>Glossary</NavDropdown.Item>
              </Link>
              <Link href="/about/team" passHref>
                <NavDropdown.Item disabled>Our team</NavDropdown.Item>
              </Link>
            </NavDropdown>
          </Nav>
          <Form
            className="d-flex"
            onSubmit={(e) => {
              e.preventDefault();
              router.push({
                pathname: "/spectra",
                query: { q: searchQuery },
              });
            }}
          >
            <FormControl
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
            <Button variant="outline-success" type="submit">
              Search
            </Button>
          </Form>
          <Nav>{isAuthenticated ? authLinks : guestLinks}</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
