import {
  passwordResetCall, passwordResetConfirmCall,
  refreshToken,
  requestRegister,
  requestUserData,
  verifyCookie
} from "../../api/auth";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RESET_REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  REFRESH_SUCCESS,
  REFRESH_FAIL,
  SET_AUTH_LOADING,
  REMOVE_AUTH_LOADING,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  WS_CONNECT
} from "../types";

export const load_user = () => async (dispatch) => {
  try {
    const res = await requestUserData();
    const data = await res;

    if (res.status === 200) {
      dispatch({
        type: LOAD_USER_SUCCESS,
        payload: data
      });
    } else {
      dispatch({
        type: LOAD_USER_FAIL
      });
    }
  } catch (err) {
    dispatch({
      type: LOAD_USER_FAIL
    });
  }
};

export const check_auth_status = () => async (dispatch) => {
  try {
    const req = await verifyCookie();
    const res = await req;

    if (res.status === 200) {
      dispatch({
        type: AUTHENTICATED_SUCCESS
      });
      dispatch(load_user());
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL
      });
    }
  } catch (err) {
    dispatch({
      type: AUTHENTICATED_FAIL
    });
  }
};

export const request_refresh = () => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADING
  });
  try {
    const req = await refreshToken();
    const res = await req;

    if (res.status === 200) {
      dispatch({
        type: REFRESH_SUCCESS
      });
      dispatch({
        type: WS_CONNECT
      });
      dispatch(check_auth_status());
    } else {
      dispatch({
        type: REFRESH_FAIL
      });
    }
  } catch (err) {
    dispatch({
      type: REFRESH_FAIL
    });
  }
  dispatch({
    type: REMOVE_AUTH_LOADING
  });
};

export const reset_register_success = () => async (dispatch) => {
  dispatch({
    type: RESET_REGISTER_SUCCESS
  });
};

// export const passwordResetConfirm = ({ password, password2, token }) => async (dispatch) => {
//   const res = await passwordResetConfirmCall({ password, password2, token });
//   if (res.status === 200) {
//     dispatch({
//       type: PASSWORD_RESET_SUCCESS
//     });
//   } else {
//     dispatch({
//       type: PASSWORD_RESET_FAIL
//     });
//   }
// };
//
// export const passwordReset = ({ email }) => async (dispatch) => {
//   const res = await passwordResetCall({ email });
//   if (res.status === 200) {
//     dispatch({
//       type: FORGOT_PASSWORD_SUCCESS
//     });
//   } else {
//     dispatch({
//       type: FORGOT_PASSWORD_FAIL
//     });
//   }
// };
