import {
  WS_CONNECTED,
  WS_CONNECT,
  WS_DISCONNECT,
  WS_DISCONNECTED,
  WS_UPDATE_PROGRESS,
  WS_UPDATE_RESULT,
  WS_TASK_STARTED
} from "../redux/types";
import cookie from "cookie";
import { WS_URL } from "../config";

const socketMiddleware = () => {
  let socket = null;

  const onOpen = (store) => (event) => {
    console.log("onOpen called, webSocket opened");
    store.dispatch({ type: WS_CONNECTED, payload: event.target.url });
  };

  const onClose = (store) => (event) => {
    console.log("onClose called, webSocket closed");
    store.dispatch({ type: WS_DISCONNECTED });
  };

  const onMessage = (store) => (event) => {
    const payload = JSON.parse(event.data);
    switch (payload.type) {
      case "task.started":
        store.dispatch({
          type: WS_TASK_STARTED,
          payload: {
            ...payload?.payload
          }
        });
        break;
      case "task.progress":
        store.dispatch({
          type: WS_UPDATE_PROGRESS,
          payload: {
            ...payload?.payload
          }
        });
        break;
      case "task.completed":
        store.dispatch({
          type: WS_UPDATE_RESULT,
          payload: {
            ...payload?.payload
          }
        });
        break;
      default:
        console.log("Invalid WS message");
        console.log(payload);
        break;
    }
  };

  return (store) => (next) => (action) => {
    switch (action.type) {
      case WS_CONNECT:
        if (socket !== null) {
          socket.close();
        }
        const token = cookie.parse(document.cookie)?.access;
        const host = WS_URL + token;

        socket = new WebSocket(host);
        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        break;

      case WS_DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;

      default:
        // action was not meant for this middleware, pass it on
        return next(action);
    }
  };
};

export default socketMiddleware();
