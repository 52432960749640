import axios from "axios";
import { API_URL } from "../config";
import cookie from "cookie";

const axiosIns = axios.create({
  baseURL: API_URL,
});

export const requestRegister = async (credentials) => {
  const { email, password, password2, firstName, lastName } = credentials;
  return axiosIns
    .post(`api/auth/register/`, {
      email,
      password,
      password2,
      profile: {
        firstName,
        lastName,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const verifyCookie = async () => {
  let accessToken = cookie.parse(document.cookie)?.access;

  return axiosIns
    .post("/api/auth/token/verify/", {
      token: accessToken
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const requestLogin = async (credentials) => {
  const { email, password } = credentials;
  return axiosIns
    .post(`api/auth/token/`, {
      email,
      password,
    })
    .then((res) => {
      let refreshCookie = `refresh=${res?.data?.refresh};path=/`;
      let accessCookie = `access=${res?.data?.access};path=/`;
      document.cookie = refreshCookie;
      document.cookie = accessCookie;
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const requestUserData = async () => {
  let token = cookie.parse(document.cookie)?.access;
  return axiosIns
    .get("api/user/me/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const refreshToken = async () => {
  let refreshToken = cookie.parse(document.cookie)?.refresh;
  let accessToken = cookie.parse(document.cookie)?.access;

  return axiosIns
    .post("api/auth/token/refresh/", {
      refresh: refreshToken,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      let refreshCookie = `refresh=${res?.data?.refresh};path=/`;
      let accessCookie = `access=${res?.data?.access};path=/`;
      document.cookie = refreshCookie;
      document.cookie = accessCookie;
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const logout = () => {
  try {
    let refreshCookie = `refresh="";${new Date(0)}`;
    let accessCookie = `access="";${new Date(0)}`;
    document.cookie = refreshCookie;
    document.cookie = accessCookie;
    return 200;
  } catch (err) {
    console.log(err);
    return 500;
  }
};

export const passwordResetConfirmCall = ({ password, password2, token }) => {
  return axiosIns
    .post("api/auth/password-reset/confirm/", {
      password,
      password2,
      token
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const passwordResetCall = ({ email }) => {
  return axiosIns
    .post("api/auth/password-reset/", {
      email: email
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
