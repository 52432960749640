import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {request_refresh} from "../../redux/actions/auth";
import Head from "next/head";
import Navbar from "../navbar";

export default function Layout({children}) {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth.loading);
    useEffect(() => {
        if (!isLoading) {
            dispatch(request_refresh());
        }
    }, []);
    return (
        <>
            <Head>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
            </Head>
            <Navbar/>
            <main className={"container"}>{children}</main>
        </>
    );
}
